import React from 'react';
import Layout from '@components/Layout/LayoutTemplates/InstitutionsLayout';
import SEO from '@components/SEO';
import FAQsModule from '@modules/Institutions/FAQs/Main';

const FAQs = () => (
  <Layout isReleased={true} isMobileComingSoon={false}>
    <SEO title="FAQs" description="FAQs" />
    <div className="FAQs-section">
      <FAQsModule />
    </div>
  </Layout>
);

export default FAQs;
